import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p><img parentName="p" {...{
        "src": "/34102d9aa9a30810acd7d9dfa4c28062/brand-dub-anatomy.svg",
        "alt": "Brand Dub Anatomy"
      }}></img></p>
    <p>{`The anatomy of the “Workday Dub” has been carefully crafted. Following the guidelines below will
ensure that the integrity of the symbol is maintained regardless of its scale.`}</p>
    <p>{`For more information about the full Workday logo, check out
`}<a parentName="p" {...{
        "href": "https://brand.workday.com/d/Umu4dggHQFn1/logos"
      }}>{`this page`}</a>{` on the Brand Central Style Guide.`}</p>
    <h2 {...{
      "id": "clear-space"
    }}>{`Clear Space`}</h2>
    <p>{`The "Workday Dub” needs breathing room to maximize its visual effectiveness. The minimum clear space
of the symbol is half the width of the W (X = W width x 0.5)`}</p>
    <p><img parentName="p" {...{
        "src": "/257eba4f83553664fe0fa35aca5399fb/brand-dub-size.svg",
        "alt": "Brand Dub Size"
      }}></img></p>
    <h2 {...{
      "id": "digital-lockup"
    }}>{`Digital Lockup`}</h2>
    <p>{`In digital applications where space is limited, it is often neccesary to use the “Workday Dub” in
the product header. Headers can be used with Canvas Kit or by following the specs below.`}</p>
    <h2 {...{
      "id": "header-usage"
    }}>{`Header Usage`}</h2>
    <h3 {...{
      "id": "spacing-guidelines"
    }}>{`Spacing Guidelines`}</h3>
    <p><img parentName="p" {...{
        "src": "/40bb9cd95a0a6bb4ea0cbd2cc80778d1/brand-dub-spacing.svg",
        "alt": "Brand Dub Spacing"
      }}></img></p>
    <h3 {...{
      "id": "color-guidelines"
    }}>{`Color Guidelines`}</h3>
    <p><img parentName="p" {...{
        "src": "/1b71551e574c42dcebf08ba8b53edf8d/brand-dub-color.svg",
        "alt": "Brand Dub Color"
      }}></img></p>
    <p><strong parentName="p">{`Primary`}</strong>{` Label: Roboto, Regular, 20px, Black Pepper 300 Divider Line: Soap400`}</p>
    <p><strong parentName="p">{`Reversed:`}</strong>{` Label: Roboto, Regular 20px, French Vanilla 100 Divider Line: FrenchVanilla100 @ 30%
Opacity`}</p>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <p>{`To use these in code, simply import the SVGs from Canvas Kit:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import {dubLogoWhite, dubLogoBlue} from '@workday/canvas-kit-react/common';
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      